import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGmpTemplateApi } from "../../store/actions/gmpTemplate";

const GmpGuide = () => {
    const dispatch = useDispatch();
    const { list } = useSelector((state) => state?.gmpTemplate);

    useEffect(() => {
        dispatch(getGmpTemplateApi());
    }, []);

    return (
        <>
            <div className="content-box pb-5">
                <section className="todo-list" style={{ paddingTop: "15px" }}>
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-10">
                                <h2 className="fs-18 fw-600 pt-2">
                                    <i className="far fa-cog" /> Guide to GMP inspection references
                                </h2>
                            </div>
                        </div>
								 
							<div className="gmp_guide">
                            <div> 
								 <div className="mb-5">
								 <h2 className="fs-18 fw-600 pt-2">Core GMP inspections</h2>
								 
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>						 
                                        <h3 className="color-05 fs-16">GMP01 - Intake</h3>
								 		<p className="color-05 fs-14">An inspection covers material and product intake, returns, non-conforming product, acceptance checks, measuring equipment, material claims, allergens, pest control, fabrication, personal hygiene and protective clothing.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP02 - Storage areas</h3>
								 		<p className="color-05 fs-14">An inspection covering material storage, temperature and controlled atmosphere, allergen storage, spills and cleaning, obsolete materials, animal feed storage, storage of returns, positive release, stock control, product handling, pest control, fabrication, personal hygiene and protective clothing.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP03 - Open material areas</h3>
								 		<p className="color-05 fs-14">An inspection covering process control, process specifications, documents and records, digital data, equipment controls, portable handheld equipment, waste, allergen controls including scheduling, traceability, measuring equipment, product inspection, fabrication, personal hygiene and protective clothing.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP04 - Packing areas</h3>
								 		<p className="color-05 fs-14">An inspection covering printing and packing controls, line control, process specifications, documents and records, digital data, equipment controls, portable handheld equipment, waste, allergen controls including scheduling, container controls, traceability, measuring equipment, product inspection, fabrication, personal hygiene and protective clothing.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP05 - Dispatch</h3>
								 		<p className="color-05 fs-14">An inspection covering vehicle and tanker loading, driver facilities, temperature control, positive release, picking, fabrication, personal hygiene and protective clothing.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP06 - External</h3>
								 		<p className="color-05 fs-14">An inspection covering hardstanding, waste handling areas, smoking areas, external building proofing, pest monitoring and cross-docking areas.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP07 - Staff facilities</h3>
								 		<p className="color-05 fs-14">An inspection covering workwear, footwear, changing facilities and rules, handwashing, toilets, lockers, and pest control.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP08 - Canteen and vending</h3>
								 		<p className="color-05 fs-14">An inspection covering catering facilities, vending, staff food storage, and pest control.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP09 - Hygiene areas</h3>
								 		<p className="color-05 fs-14">An inspection covering cleaning controls required for areas such as tub wash rooms, manual cleaning areas or cleaning storage areas.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP10 - Risk-based open product areas</h3>
								 		<p className="color-05 fs-14">An inspection for ambient high-care, high-risk and high-care zones, covering everything that's included in GMP03 for open material areas, plus dedicated equipment, transfer points, air controls, drains, specific changing and facilities.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP11 - Risk-based packing areas</h3>
								 		<p className="color-05 fs-14">An inspection for ambient high-care, high-risk and high-care zones, covering everything that's included in GMP04 for packing areas, plus dedicated equipment, transfer points, air controls, drains, specific changing and facilities.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow">	
                                        <h3 className="color-05 fs-16">GMP12 - Enclosed product area</h3>
								 		<p className="color-05 fs-14">An inspection covering process control, documents and records, digital data, equipment controls, measuring equipment, fabrication, personnel controls.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow">	
                                        <h3 className="color-05 fs-16">GMP13 - Open material areas, no allergens</h3>
								 		<p className="color-05 fs-14">An inspection covering process control, process specifications, documents and records, digital data, equipment controls, portable handheld equipment, waste, traceability, measuring equipment, product inspection, fabrication, personal hygiene and protective clothing.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP14 - Packing areas, no allergens</h3>
								 		<p className="color-05 fs-14">An inspection covering printing and packing controls, line control, process specifications, documents and records, digital data, equipment controls, portable handheld equipment, waste, container controls, traceability, measuring equipment, product inspection, fabrication, personal hygiene and protective clothing.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP15 - M&S FIND specification audit</h3>
								 		<p className="color-05 fs-14">Covers the Right First Time FIND specification checklist. Each product to be audited at least once every 2 years of supply.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP20 - Maintenance inspection</h3>
								 		<p className="color-05 fs-14">An inspection for maintenance controls, including the engineering workshop and storage areas, contractors, handback, temporary structures, tools and repairs in product areas.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP21 - Hygiene inspection</h3>
								 		<p className="color-05 fs-14">An inspection which focuses on hygiene related controls including practical application of cleaning procedures, monitoring and verification, chemical control, cleaning equipment and facilities.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP22 - Foreign body controls and CCPs</h3>
								 		<p className="color-05 fs-14">An inspection which focuses on foreign body controls and product safety controls (CCPs).<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP23 - Allergen free-from inspection</h3>
									 	<p className="color-05 fs-14">TBC<br /></p>
                                 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
										<h3 className="color-05 fs-16">GMP24 - AIB Food Defence Inspection</h3>
								 		<p className="color-05 fs-14">An inspection which focuses on protection measures, to comply with the AIB Food Defence standard.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP25 - Hygiene inspection with risk based controls</h3>
								 		<p className="color-05 fs-14">An inspection which focuses on hygiene related controls including high-risk controls, practical application of cleaning procedures, monitoring and verification, chemical control, cleaning equipment and facilities.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP26 - Staff facilities with risk based controls</h3>
								 		<p className="color-05 fs-14">An inspection covering low risk and high-risk/high-care workwear, footwear, changing facilities and rules, handwashing, toilets, lockers, and pest control.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP27 - Retailer specification check</h3>
								 		<p className="color-05 fs-14">This inspection checks that the product being produced complies with the retailer specification. Typically, these should be completed at least once every 2 years, or before a change is made to the product or process - whichever is sooner. Note, this is applicable to Morrisons.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">GMP28 - Speciation inspection</h3>
								 		<p className="color-05 fs-14">If your site handles more than one type of meat, this inspection is designed to ensure that the necessary controls are in place and effective. Note, this is applicable to Morrisons.<br /></p>
								 </div>
								 
                                      
	
	 									<h2 className="fs-18 fw-600 pt-2">Combined GMP inspections</h2>

								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">COM01 - GMP01, GMP02, GMP03 and GMP04</h3>
								 		<p className="color-05 fs-14">This inspection includes all the questions from GMP01, GMP02, GMP03 and GMP04 without any of the repetition.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">COM02 - GMP05 and GMP06</h3>
								 		<p className="color-05 fs-14">This inspection includes all the questions from GMP05 and GMP06, without any of the repetition.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">COM03 - GMP07 and GMP08</h3>
								 		<p className="color-05 fs-14">This inspection includes all the questions from GMP07 and GMP08, without any of the repetition.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">COM04 - GMP04 and GMP05</h3>
								 		<p className="color-05 fs-14">This inspection includes all the questions from GMP04 and GMP05, without any of the repetition.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">COM05 - GMP01, GMP02, GMP05 and GMP06</h3>
								 		<p className="color-05 fs-14">This inspection includes all the questions from GMP01, GMP02, GMP05 and GMP06, without any of the repetition.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">COM06 - GMP02 and GMP20</h3>
								 		<p className="color-05 fs-14">This inspection includes all the questions from GMP02 and GMP20, without any of the repetition.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">COM07 - GMP02 and GMP06</h3>
								 		<p className="color-05 fs-14">This inspection includes all the questions from GMP02 and GMP06, without any of the repetition.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">COM08 - GMP01, GMP02 and GMP05</h3>
								 		<p className="color-05 fs-14">This inspection includes all the questions from GMP01, GMP02, and GMP05 without any of the repetition.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">COM09 - GMP01, GMP02 and GMP03</h3>
								 		<p className="color-05 fs-14">This inspection includes all the questions from GMP01, GMP02, and GMP03 without any of the repetition.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">COM10 - GMP01 and GMP02</h3>
								 		<p className="color-05 fs-14">This inspection includes all the questions from GMP01 and GMP02, without any of the repetition.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">COM11 - GMP01, GMP02, GMP13, GMP04, GMP05, GMP06, GMP07, GMP08, GMP09, GMP20 and GMP22.</h3>
								 		<p className="color-05 fs-14">This inspection includes all the questions from GMP01, GMP02, GMP04, GMP05, GMP06, GMP07, GMP08, GMP09, GMP13, GMP20 and GMP22, without any of the repetition.<br /></p>
								 </div>
								 <div role="row" class="rdt_TableRow" style={{ padding: "10px" }}>	
                                        <h3 className="color-05 fs-16">COM12 - GMP03, GMP04 and GMP09</h3>
								 		<p className="color-05 fs-14">This inspection includes all the questions from GMP03, GMP04 and GMP09 without any of the repetition.<br /></p>
								</div>
<p className="color-05 fs-14"><strong>* In most instances GEN and EXT inspections can be ignored. </strong></p>


							</div>
	</div>
								 </div>

                    </div>
                </section>
            </div>
        </>
    );
};

export default GmpGuide;
